import { Naja } from 'naja/dist/Naja'
import naja from 'naja'
import { BootstrapAjaxModal } from '@/js/App/BootstrapAjaxModal'
import { FormTabsStateExtension } from '@/js/ui/naja/FormTabsStateExtension'
import {
	AjaxModalExtension,
	AjaxModalPreventRedrawExtension,
	AjaxOnceExtension,
	ConfirmExtension,
	FollowUpRequestExtension,
	ForceRedirectExtension,
	SnippetFormPartExtension,
	SpinnerExtension
} from '@peckadesign/pd-naja'
import { getSpinnerProps, Spinner } from '@/js/App/Spinner'

declare global {
	interface Window {
		naja: Naja
	}
}

naja.registerExtension(new AjaxModalExtension(new BootstrapAjaxModal()))
naja.registerExtension(new AjaxOnceExtension())
naja.registerExtension(new ConfirmExtension())
naja.registerExtension(new FollowUpRequestExtension())
naja.registerExtension(new ForceRedirectExtension())
naja.registerExtension(new FormTabsStateExtension())
naja.registerExtension(new AjaxModalPreventRedrawExtension())
naja.registerExtension(new SnippetFormPartExtension())
naja.registerExtension(
	new SpinnerExtension(
		Spinner,
		getSpinnerProps,
		undefined,
		// We cannot modify the template (3rd party library), therefore we're modifying the selector for placeholder
		// element to also find specifically `.datagrid tbody`.
		'.ajax-spinner, .datagrid tbody'
	)
)

// We must attach Naja to window load event.
document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja))

window.naja = naja

export default naja
