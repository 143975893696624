import Control from '@peckadesign/pd-naja/dist/utils/Control'
import '@/js/App/FormIsChanged'

class SaveWebContainerControl implements Control {
	public initialize(context: Element | Document): void {
		const webContainers = context.querySelectorAll<HTMLElement>('.js-web-container')

		webContainers.forEach((container) => {
			const control = container.querySelector<HTMLInputElement>('.js-web-container__control')

			if (!control) {
				return
			}

			this.initializeWebContainer(container, control)
		})
	}

	private initializeWebContainer(container: HTMLElement, control: HTMLInputElement): void {
		control.checked = false

		const containerFormElements = container.querySelectorAll<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>('input, textarea, select')

		container.addEventListener('change', (event: Event) => {
			if (event.target === control) {
				return
			}

			// Disabled elements are ignored inside FormData, therefore whether this has been changed won't affect the
			// outcome of `isChanged` method.
			control.disabled = true

			const changed = control.form !== null && control.form.isChanged(container)

			// Toggle the element only if neccessary
			if (control.checked !== changed) {
				control.checked = changed

				// After toggling the control checkbox, trigger validatoion all inputs inside container. This
				// hides / shows error messages based on current control checkbox state.
				containerFormElements.forEach((formElement) => {
					formElement.dispatchEvent(new Event('validate', { bubbles: true }))
				})
			}

			control.disabled = false
		})
	}
}

export default new SaveWebContainerControl()
