export default class FileInput {
	private input: HTMLInputElement
	private group: HTMLElement
	private clearButton?: Node

	public constructor(element: Element, selector: string) {
		const input = element.querySelector('[type="file"]')
		const template = element.querySelector('template')
		const group = element.querySelector(`${selector}__group`)

		if (!input || !template || !group) {
			throw new Error('Invalid file input template.')
		}

		this.input = input as HTMLInputElement
		this.group = group as HTMLElement
		this.clearButton = template?.content?.firstElementChild?.cloneNode(true)
		this.clearButton?.addEventListener('click', (event: Event) => this.clearInput(event))

		input.addEventListener('change', () => this.handleChange())
	}

	private handleChange(): void {
		this.input.value ? this.showClearButton() : this.hideClearButton()
	}

	private clearInput(event: Event): void {
		event.preventDefault()

		this.input.value = ''
		this.input.dispatchEvent(new Event('change', { bubbles: true }))
	}

	private showClearButton(): void {
		if (!this.clearButton || this.clearButton.isConnected) {
			return
		}

		this.input.after(this.clearButton)
	}

	private hideClearButton(): void {
		if (!this.clearButton || !this.clearButton.isConnected) {
			return
		}

		;(this.clearButton as HTMLButtonElement).remove()
	}
}
