import FileInput from '@/js/App/FileInput'
import Control from '@peckadesign/pd-naja/dist/utils/Control'

class FileInputControl implements Control {
	private readonly selector = '.js-file-input'

	public initialize(context: Element | Document): void {
		const fileInputs = context.querySelectorAll(this.selector)

		fileInputs.forEach((fileInput) => new FileInput(fileInput, this.selector))
	}
}

export default new FileInputControl()
